const types = {
  initDimensions: 'initDimensions',
  setFilteredDimensions: 'setFilteredDimensions'
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.initDimensions:
      const { dimensions, dependencies } = action;
      const dimensionsMapper = {};
      const dependenciesMapper = {};

      // remove evaluation from itemTypes. it's not relevant for the students.
      let filteredItemTypes = dimensions.subTypes.filter(item => item.groupName === 'catalog' && item.id !== 'evaluation');
      dimensions.subTypes = filteredItemTypes;

      Object.keys(dimensions).forEach(key => {
        dimensions[key].sort((a, b) => (a.orderBy < b.orderBy ? -1 : a.orderBy > b.orderBy ? 1 : 0));
        if (!dimensionsMapper[key]) {
          dimensionsMapper[key] = {};
          dimensions[key].forEach(item => {
            dimensionsMapper[key][item.id] = item;
          });
        }
      });

      // Helper function to ensure nested objects exist
      const ensurePath = (obj, keys) => {
        return keys.reduce((acc, key) => {
          if (!acc[key]) acc[key] = {};
          return acc[key];
        }, obj);
      };

      dependencies.ageGradesDisciplines.forEach(({ ageGradeId, disciplineId }) => {
        ensurePath(dependenciesMapper, ['ageGrades', 'disciplines', ageGradeId])[disciplineId] = true;
        ensurePath(dependenciesMapper, ['disciplines', 'ageGrades', disciplineId])[ageGradeId] = true;
      });

      dependencies.disciplineSectors.forEach(({ disciplineId, sectorId }) => {
        ensurePath(dependenciesMapper, ['disciplines', 'sectors', disciplineId])[sectorId] = true;
        ensurePath(dependenciesMapper, ['sectors', 'disciplines', sectorId])[disciplineId] = true;
      });

      dependencies.disciplineSkillsDependencies.forEach(({ skillId, disciplineId, ageGradeId }) => {
        ensurePath(dependenciesMapper, ['skills', 'ageGrades', skillId])[ageGradeId] = true;
        ensurePath(dependenciesMapper, ['ageGrades', 'skills', ageGradeId])[skillId] = true;

        ensurePath(dependenciesMapper, ['skills', 'disciplines', skillId])[disciplineId] = true;
        ensurePath(dependenciesMapper, ['disciplines', 'skills', disciplineId])[skillId] = true;
      });

      dependencies.generalSkillsAgeGrades.forEach(({ id, ageGradeId }) => {
        ensurePath(dependenciesMapper, ['generalSkills', 'ageGrades', id])[ageGradeId] = true;
        ensurePath(dependenciesMapper, ['ageGrades', 'generalSkills', ageGradeId])[id] = true;
      });

      dependencies.syllabusSubjectsDependencies.forEach(({ syllabusSubjectId, disciplineId, ageGradeId }) => {
        ensurePath(dependenciesMapper, ['syllabusSubjects', 'ageGrades', syllabusSubjectId])[ageGradeId] = true;
        ensurePath(dependenciesMapper, ['ageGrades', 'syllabusSubjects', ageGradeId])[syllabusSubjectId] = true;

        ensurePath(dependenciesMapper, ['syllabusSubjects', 'disciplines', syllabusSubjectId])[disciplineId] = true;
        ensurePath(dependenciesMapper, ['disciplines', 'syllabusSubjects', disciplineId])[syllabusSubjectId] = true;
      });

      return { dimensions: dimensions, dimensionsMapper: dimensionsMapper, dependencies: dependencies, dependenciesMapper: dependenciesMapper, filteredDimensions: {} };
    case types.setFilteredDimensions:
      const { filteredDimensions } = action;
      return { ...state, filteredDimensions: filteredDimensions.dimensions };
    default:
      return state;
  }
};

export const initDimensions = ({ dimensions, dependencies }) => ({
  type: types.initDimensions,
  dimensions,
  dependencies
});
export const setFilteredDimensions = ({ filteredDimensions }) => ({
  type: types.setFilteredDimensions,
  filteredDimensions
});
export default reducer;
