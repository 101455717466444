import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { activitiesService } from '../services/activities';
import { booksService } from '../services/books';
import { dataStructure } from '../utils/data-structure';
import { setActivities } from '../state/activities';

export const useBooks = (maxResults, discipline) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBooksLoading, setIsBooksLoading] = useState(true);
  const [isMyBooksLoading, setIsMyBooksLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [books, setBooks] = useState(null);
  const [myBooks, setMyBooks] = useState(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  const { studentId, schoolId, gradeId, activities, sectors, schoolStartDate } = useSelector(state => {
    const profile = state.profile ?? {};

    return {
      studentId: profile.studentId,
      schoolId: profile.schoolId,
      gradeId: profile.gradeId,
      // allDisciplines: state.configurations.allDisciplines,
      activities: state.activities.books,
      sectors: state?.profile?.schoolSectorId,
      schoolStartDate: state.configurations.schoolStartDate,
    };
  });

  useEffect(() => {
    const loadingCounter = setTimeout(() => {
      if (isLoading) {
        setIsErrored(true);
      }
    }, 20 * 1000);
    return () => {
      clearTimeout(loadingCounter);
    };
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(isBooksLoading || isMyBooksLoading);
  }, [isBooksLoading, isMyBooksLoading]);

  // Load activities
  useEffect(() => {
    if (studentId && !activities) {
      activitiesService
        .loadActivities(studentId, 'BOOK', schoolId)
        .then(result => {
          dispatch(setActivities({ type: 'books', activities: result }));
        })
        .catch(error => {
          console.error(error);
          dispatch(setActivities({ type: 'books', activities: [] }));
        });
    }
  }, [activities, dispatch, schoolId, studentId]);

  const { allDimensions } = useSelector(({ dimensions }) => {
    return {
      allDimensions: dimensions.dimensions,
    };
  });

  // Load books
  useEffect(() => {
    setIsBooksLoading(true);
    if (studentId && maxResults > 0) {
      setIsErrored(false);

      booksService
        .search(gradeId, discipline, intl.locale, maxResults, sectors)
        .then(result => {
          const booksArray = dataStructure.convertSearchRecordsToArray(result.records, allDimensions.disciplines);

          booksArray.forEach(discipline => {
            discipline.records = discipline.records
              .filter(record => {
                return !record.itemTypes.teacherRef;
              })
              .sort((a, b) => (a.title < b.title ? -1 : 1));
          });

          setBooks({
            data: booksArray,
            stats: result.stats
          });
          setIsBooksLoading(false);
        })
        .catch(error => {
          console.error(error);
          setIsBooksLoading(false);
          setIsErrored(true);
        });
    }
  }, [discipline, gradeId, intl.locale, maxResults, studentId]);

  // Set my books
  useEffect(() => {
    setIsMyBooksLoading(true);
    if (activities?.length > 0) {
      const activitiesObj = dataStructure.convertArrayToObject(activities, 'externalId');
      const externalIds = activities.map(activity => activity.externalId);

      booksService.searchByExternalIds(intl.locale, externalIds).then(result => {
        const myBooks = result.records.all
          .map(myBook => {
            return { ...myBook, additionalInfo: activitiesObj[myBook.externalContextId].additionalInfo };
          })
          .filter(myBook => !myBook.itemTypes.teacherRef)
          .sort((a, b) => {
            try {
              const activityA = activitiesObj[a.externalContextId];
              const activityB = activitiesObj[b.externalContextId];
              const infoA = JSON.parse(activityA?.additionalInfo);
              const infoB = JSON.parse(activityB?.additionalInfo);
              const timestampA = infoA?.timestamp;
              const timestampB = infoB?.timestamp;
              return new Date(timestampA).getTime() > new Date(timestampB).getTime() ? -1 : 1;
            } catch (err) {
              console.error(err);
            }
            return 0;
          })
          .filter(myBook => {
            const additionalInfo = JSON.parse(myBook.additionalInfo);
            const bookTimestamp = new Date(additionalInfo.timestamp);
            return bookTimestamp >= new Date(schoolStartDate);
          });
        setMyBooks(myBooks);
      });
    }
    setIsMyBooksLoading(false);
  }, [activities, intl.locale]);

  const handleRemoveBook = async externalId => {
    try {
      await activitiesService.removeBookFromMyBooks(studentId, 'GLOBAL', externalId);
      dispatch(setActivities({ type: 'books', activities: activities.filter(book => book.externalId !== externalId) }));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return { isLoading, isErrored, books, myBooks, handleRemoveBook };
};
