import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tasksService } from '../services/tasks';
import { setSchoolYearRange } from '../state/configurations';

const NUMBER_OF_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 4;

/**
 * @param {string} status // null | 'evaluated' | 'submitted' | 'returned'
 * @param {string} disciplineId
 * @param {Date} minDate
 * @param {Date} maxDate
 */
export const useTasks = (disciplineId, shouldLoadTasks, shouldDisplayLoader) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [allTasks, setAllTasks] = useState({});
  const [tasks, setTasks] = useState({});
  const [disciplines, setDisciplines] = useState({});
  const dispatch = useDispatch();

  const { allDimensions } = useSelector(({ dimensions }) => {
    return {
      allDimensions: dimensions.dimensions,
    };
  });

  const { studentId, schoolId, minDate, maxDate, isHigh } = useSelector(state => {
    const { gradeId } = state.profile ?? {};

    return {
      studentId: state.profile?.studentId ?? '',
      schoolId: state.profile?.schoolId ?? '',
      minDate: state.configurations.schoolStartDate,
      maxDate: state.configurations.schoolEndDate,
      // allDisciplines: state.configurations.allDisciplines,
      isHigh: Number(gradeId.split('grade')[1]) >= 7
    };
  });

  const updateSchoolYearRange = useCallback(
    ({ start, end }) => {
      dispatch(setSchoolYearRange({ start, end }));
    },
    [dispatch]
  );

  const reduceTasksByStatus = useCallback(tasks => {
    const reduced = tasks.reduce((prev, task) => {
      let status = task.taskStatus && task.taskStatus.toLowerCase() !== 'started' && task.taskStatus.toLowerCase() !== 'startedtrial' ? task.taskStatus.toLowerCase() : 'todo';

      if (status?.toLowerCase() === 'submitted' && task.taskType?.toLowerCase() === 'learningwork') {
        status = 'evaluated';
      }

      if (!prev[status]) {
        prev[status] = { all: [], urgent: [], other: [], late: [] };
      }

      if (new Date(task.taskDueDate) < new Date()) {
        prev[status].late.push(task);
      } else if (new Date().getTime() - new Date(task.taskDueDate).getTime() < NUMBER_OF_DAYS_IN_MS) {
        prev[status].urgent.push(task);
      } else {
        prev[status].other.push(task);
      }
      return prev;
    }, {});

    Object.keys(reduced).forEach(status => {
      Object.keys(reduced[status]).forEach(key => {
        if (status === 'todo') {
          reduced[status][key] = reduced[status][key].sort((t1, t2) => {
            if (!t1.taskDueDate) return 1;

            const now = new Date().getTime();
            const t1Time = new Date(t1.taskDueDate).getTime();
            const t2Time = new Date(t2.taskDueDate).getTime();

            if (t1Time < now) {
              if (t1Time <= t2Time) {
                return 1;
              } else {
                return -1;
              }
            } else if (t1Time <= t2Time) {
              return -1;
            }

            return 0;
          });
        } else {
          reduced[status][key] = reduced[status][key].sort((t1, t2) => {
            if (!t1.taskStatusDate) return 1;

            const t1Time = new Date(t1.taskStatusDate).getTime();
            const t2Time = new Date(t2.taskStatusDate).getTime();

            if (t1Time > t2Time) {
              return -1;
            }

            return 0;
          });

          return 0;
        }
      });

      reduced[status].all = [...reduced[status].urgent, ...reduced[status].other, ...reduced[status].late];
    });

    setTasks(reduced);
  }, []);

  const reduceDisciplines = useCallback(
    tasks => {
      const reduced = tasks.reduce((activeDisciplines, task) => {
        if (task.taskDisciplines) {
          task.taskDisciplines.forEach(taskDiscipline => {
            if (!activeDisciplines[taskDiscipline]) {
              activeDisciplines[taskDiscipline] = allDimensions.disciplines.find(d => d.id === taskDiscipline).name;
            }
          });
        }

        return activeDisciplines;
      }, {});

      setDisciplines(reduced);
    },
    [allDimensions.disciplines]
  );

  useEffect(() => {
    if (allTasks?.length) {
      if (disciplineId) {
        const filteredByDiscipline = allTasks.filter(task => {
          return !disciplineId | (disciplineId === 'all') || (task.taskDisciplines && task.taskDisciplines.find(discipline => discipline === disciplineId));
        });
        reduceTasksByStatus(filteredByDiscipline);
      } else {
        reduceTasksByStatus(allTasks);
      }
    }
  }, [allTasks, disciplineId, reduceTasksByStatus]);

  const loadTasksFromAPI = useCallback(async () => {
    setIsLoading(shouldDisplayLoader);
    setIsErrored(false);
    try {
      const tasks = await tasksService.loadTasks({ studentId, schoolId, minDate, maxDate, includeQuestions: isHigh });
      setAllTasks(tasks);
      reduceDisciplines(tasks);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setIsErrored(true);
    }
  }, [isHigh, maxDate, minDate, reduceDisciplines, schoolId, shouldDisplayLoader, studentId]);

  useEffect(() => {
    if (shouldLoadTasks) {
      loadTasksFromAPI();
    }
  }, [isHigh, maxDate, minDate, reduceDisciplines, schoolId, studentId, shouldLoadTasks, shouldDisplayLoader, loadTasksFromAPI]);

  return { isLoading, isErrored, allTasks, tasks, disciplines, updateSchoolYearRange };
};
