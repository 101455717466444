const STORAGE_URL = `//nairobitestingstorage.blob.core.windows.net`;
const AVATAR_STORAGE_URL = `${STORAGE_URL}/usersuploads/avatrs/`;
const THUMBNAIL_STORAGE_URL = `${STORAGE_URL}/thumbnails/`;

export const imagesService = {
  getThumbnailUrl: (id, width, height, getStorageUrl = true) => {
    return id
      ? getStorageUrl
        ? `${THUMBNAIL_STORAGE_URL}${id}/w_${width}_h_${height}.png`
        : ``
      // : window.cet.microservices.contentapi.thumbnails.getImageUrl({ id, width, height })
      : '';
  },
  getAvatarUrl: id => `${AVATAR_STORAGE_URL}${id}`
};
