import { getStudyYearDataByDate } from '../legacyComponents/utils/helpers';
import { callWithCache } from '../utils/callWithCache';
import { catalogApiPaths, nairobiGateway } from '../utils/urls';

export const configurationsService = {
  loadSchoolYearRange: async () => {
    return new Promise(resolve => {
      const dates = getStudyYearDataByDate(new Date());
      resolve({
        start: dates.start.toString(),
        end: dates.end.toString()
      });
    });
  },

  loadLabels: async (systemLanguages = ['he', 'ar']) => {
    const languages = systemLanguages.join(',');
    return await callWithCache(window.cet.microservices.nairobiapi.label.getLabels, 'getLabels', { languages }, 3);
  },

  loadAllDimensions: async lang => {
    return callWithCache(
      () =>
        fetch(`${nairobiGateway}${catalogApiPaths.getAllDimensions}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({
            lang: lang,
            includeTags: false,
          })
        }).then(response => response.json()),
      'allDimensions',
      { lang }
    );
  },

  loadAllDependencies: async => {
    return callWithCache(
      () =>
        fetch(`${nairobiGateway}${catalogApiPaths.getAllDependencies}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
        }).then(response => response.json()),
      'allDependencies'
    );
  }
};
