import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CacheProvider } from '../utils/cacheProvider';
import { isSearchTermNothingButNumber } from '../utils/search';
import { catalogApiPaths, nairobiGateway } from '../utils/urls';

export const parameterFormation = {
  ageGrades: 'filters',
  categoryType: 'filters',
  disciplineSkills: 'filters',
  disciplineSubSkills: 'filters',
  disciplines: 'filters',
  generalSkills: 'filters',
  generalSubSkills: 'filters',
  id: 'filters',
  isAllAutomaticQuestions: 'filters',
  isPublished: 'filters',
  language: 'filters',
  mainType: 'filters',
  sectors: 'filters',
  sourceSystemId: 'filters',
  subCategoryType: 'filters',
  subTypes: 'filters',
  subTypeGroups: 'filters',
  syllabusDetails: 'filters',
  syllabusSubjects: 'filters',
  syllabusSubSubjects: 'filters',
  tags: 'filters',
  yearCycles: 'filters',
  duration: 'sorting',
  lastPublishedDate: 'sorting',
  lastUpdatedDate: 'sorting',
  allFields: 'searchText'
};

const allSectors = ['state', 'mmad', 'arab'];

const search =
  (intl, allDimensions, sectors) =>
    /**
     *
     * @param {Array.<Object>} searchByParameters Array of search parameters. ex: [{p2: [1,2,3]}, {p2: [9,8,7]}]
     * @param {string} groupBy Any dimension name to group by.
     * @param {number} maxResults Max number of item per group
     * @param {string} orderBy
     * @param {string} searchFunk the search function to use. 'search' or 'parallelSearchFast'
     * @param {boolean} withSectors
     * @param {string} orderByOrder '+' or '-' for ascending or descending order
     */
    async (searchByParameters, groupBy, maxResults, orderBy = 'LastPublishedDate', searchFunk = 'search', withSectors = true, orderByOrder = '+') => {
      if (!allDimensions || (withSectors && !sectors)) return null;
      if (isSearchTermNothingButNumber(searchByParameters)) return [];

      if (searchByParameters) {
        const createApiParameters = searchByParameters => {
          const apiParameters = {
            query: {
              filters: {
                language: intl.locale,
                sectors: withSectors ? sectors : allSectors,
                isPublished: true,
                audience: 'students'
              },
              searchText: ''
            },
            paging: { pageNumber0based: 0, pageSize: maxResults },
            sorting: {},
            distributionField: 'SubTypeGroups'
          };

          if (groupBy && searchFunk !== 'filter') {
            apiParameters.query.filters.groupBy = groupBy;
          }

          if (orderBy) {
            apiParameters.sorting[orderBy] = orderByOrder;
          }

          if (maxResults) {
            apiParameters.paging.pageSize = maxResults;
          }

          // Extract the value of allFields and assign it to searchText
          const extractSearchText = (parameters) => {
            const allFieldsParam = parameters.find(param => param.allFields);
            return allFieldsParam ? allFieldsParam.allFields.replace(/^"|"$/g, '') : '';
          };

          apiParameters.query.searchText = extractSearchText(searchByParameters);

          searchByParameters.forEach(parameter => {
            const parameterName = Object.keys(parameter)[0];

            // Skip processing allFields as it is already handled
            if (parameterName === 'allFields') {
              return;
            }

            if (parameterFormation[parameterName]) {
              const targetSection = parameterFormation[parameterName];
              if (!apiParameters.query[targetSection]) {
                apiParameters.query[targetSection] = {};
              }
              apiParameters.query[targetSection][parameterName] = parameter[parameterName];
            } else {
              apiParameters.query.filters[parameterName] = parameter[parameterName];
            }
          });

          return apiParameters;
        };

        let apiParameters;
        if (searchFunk === 'parallelSearchFast') {
          apiParameters = [];
          searchByParameters.forEach(params => {
            apiParameters.push(createApiParameters(params));
          });
        } else {
          apiParameters = createApiParameters(searchByParameters);
        }

        let localCacheKey = CacheProvider.generateKey('search', apiParameters);
        let items;
        if (CacheProvider.get(localCacheKey)) {
          items = CacheProvider.get(localCacheKey);
        } else {
          const getResults = response => {
            const items = [];

            if (response?.isValidResponse && response?.resultSet) {
              const resultSet = response.resultSet;

              if (groupBy === 'disciplines' || groupBy === 'yearCycles') {
                // Group by disciplines or yearCycles
                const groupedItems = {};

                resultSet.forEach(item => {
                  const groupByField = groupBy === 'disciplines' ? item.disciplines : item.yearCycles;
                  const groupArray = Array.isArray(groupByField) ? groupByField : [];

                  groupArray.forEach(group => {
                    const groupId = group.id || group;
                    if (!groupedItems[groupId]) {
                      groupedItems[groupId] = {
                        [groupBy]: groupId,
                        title: '',
                        data: [],
                        totalCount: 0
                      };
                    }
                    groupedItems[groupId].data.push(item);
                    groupedItems[groupId].totalCount += 1;
                  });
                });

                Object.keys(groupedItems).forEach(groupKey => {
                  const group = groupedItems[groupKey];
                  if (allDimensions?.[groupBy]) {
                    const foundGroup = allDimensions[groupBy].find(g => g.id === groupKey);
                    group.title = foundGroup ? foundGroup.name : group.title;
                  }
                  items.push(group);
                });
              } else {
                // General grouping (not by disciplines)
                const groupedItems = {};
                const allData = []; // Only used when NOT grouping by disciplines

                resultSet.forEach(item => {
                  allData.push(item); // Collect all items for the "all" group
                  if (Array.isArray(item.subTypes)) {
                    item.subTypes.forEach(subType => {
                      const subTypeId = subType.id || subType;
                      if (!groupedItems[subTypeId]) {
                        groupedItems[subTypeId] = [];
                      }
                      groupedItems[subTypeId].push(item);
                    });
                  }
                });

                // Process each subType
                Object.keys(groupedItems).forEach(key => {
                  let title = allDimensions?.subTypes?.find(item => item.id === key)?.name || intl.formatMessage({ id: key, defaultMessage: '' });
                  items.push({
                    subTypes: key,
                    title,
                    data: groupedItems[key],
                    totalCount: groupedItems[key].length,
                    distributionField: response?.distribution
                  });
                });

                // Add the "all" group only when not grouping by disciplines
                items.unshift({
                  subTypes: 'all',
                  title: intl.formatMessage({ id: 'catalogAll', defaultMessage: '' }),
                  data: allData,
                  totalCount: allData.length,
                  distributionField: response?.distribution
                });
              }
            }
            return items;
          };

          try {
            let res;
            if (searchFunk === 'parallelSearchFast') {
              res = await Promise.all(
                apiParameters.map(async (params) => {
                  const response = await fetch(`${nairobiGateway}${catalogApiPaths.search}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(params)
                  });
                  return getResults(await response.json());
                })
              );
              items = res;
            } else {
              res = await fetch(`${nairobiGateway}${catalogApiPaths.search}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(apiParameters)
              });
              const response = await res.json();
              items = getResults(response);
            }

            CacheProvider.set(localCacheKey, JSON.stringify(items));
          } catch (error) {
            console.error(error);
          }
        }
        return items;
      }
    };

export const useSearch = () => {
  const intl = useIntl();

  const { allDimensions, schoolSectorId } = useSelector(({ profile, dimensions }) => {
    return {
      allDimensions: dimensions.dimensions,
      schoolSectorId: profile?.isExtendedCatalog ? ['state', 'mmad', 'arab'] : profile?.schoolSectorId ? [profile.schoolSectorId] : locale === 'ar' ? ['arab'] : ['state', 'mmad']
    };
  });

  return useMemo(() => search(intl, allDimensions, schoolSectorId), [allDimensions, intl, schoolSectorId]);
};
